import { Namespace, TFunction, i18n } from 'i18next';
import { PeriodicReviewConfig } from '../models/Form';
import { ClientForm } from '../models/ClientForm';
import { ClientFormDefault } from '../models/ClientFormDefaults';
import { DateIntervalKeys } from '../models/DateInterval';
import DateUtils from './DateUtils';

export class PeriodicReviewUtils {
  public static getConfig(input: ClientForm | ClientFormDefault) {
    // clientform
    if ('form' in input) {
      return input.periodicReviewConfig;
    }

    // defaults
    if (input.defaults?.configuration.periodicReviewConfig) {
      return input.defaults?.configuration.periodicReviewConfig;
    }
    if (input.templateForm.periodicReviewConfig) {
      return input.templateForm.periodicReviewConfig;
    }
    return null;
  }

  public static getRecurrence(config: PeriodicReviewConfig, t: TFunction<['periodic-review', 'common']>) {
    if (config.recurrenceFrequencyAmount === 0) {
      return t('periodic-review:frequency.once');
    }

    return t('periodic-review:tooltip.frequency-text', {
      amount: config.recurrenceFrequencyAmount,
      interval: t(DateIntervalKeys[config.recurrenceFrequencyInterval]),
    });
  }

  public static getRecurrenceDueDate(config: PeriodicReviewConfig, t: TFunction<['periodic-review', 'common']>, i18next: i18n) {
    if (config.initialReviewDate) {
      return DateUtils.formatDayLongMonth(new Date(config.initialReviewDate), i18next.language);
    }
    if (config.initialReviewAmount > 0) {
      return t('periodic-review:tooltip.due-text', {
        amount: config.initialReviewAmount,
        interval: t(DateIntervalKeys[config.initialReviewInterval]),
      });
    }

    return '';
  }

  public static getAdvancedNoticeText(config: PeriodicReviewConfig, t: TFunction<Namespace>) {
    if (config.noticeAmount === 0) {
      return '';
    }

    return t('periodic-review:tooltip.advanced-notice-text', {
      amount: config.noticeAmount,
      interval: t(DateIntervalKeys[config.noticeInterval]),
    });
  }

  public static getRecurrenceEndText(config: PeriodicReviewConfig, t: TFunction<['periodic-review', 'common']>) {
    if (config.recurrenceEndFixedDate) {
      return DateUtils.formatDate(new Date(config.recurrenceEndFixedDate));
    }
    if (config.recurrenceEndOccurrences > 0) {
      return t('periodic-review:tooltip.recurrence-end-text', { amount: config.recurrenceEndOccurrences });
    }

    return t('periodic-review:recurrence-end.never');
  }

  public static validate(config: PeriodicReviewConfig | undefined | null, t: TFunction<Namespace>) {
    const result: string[] = [];
    if (!config) {
      return result;
    }

    // One of: initial review count & no initial review date, must be set
    if (!config.initialReviewAmount && !config.initialReviewDate) {
      result.push(t('periodic-review:validation-errors.initial-review-date-count'));
    }

    if (config.initialReviewDate && config.recurrenceEndFixedDate) {
      const initial = new Date(config.initialReviewDate);
      const end = new Date(config.recurrenceEndFixedDate);

      // end date is before initial date
      if (end.getTime() < initial.getTime()) {
        result.push(t('periodic-review:validation-errors.end-fixed-date-before-initial-fixed-date'));
      }
    }

    return result;
  }
}
